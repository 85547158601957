.home-8
    .section-title
        margin-bottom: r(20px)
    .social-link 
        margin-top: r(30px)
        color: color(main)
        font-size: r(18px)
        > * 
            + *
                margin-left: 15px
    .subscribe-btn
        min-width: r(270px)
        margin-top: r(30px)
    .partner-list
        margin-top: r(30px)
        display: flex
        max-width: r(690px)
        margin-left: auto
        margin-right: auto
        .partner
            text-align: center
            min-height: r(190px)
            background: #0e2044
            padding: r(25px)
            width: 50%
            .title
                color: #999999
                text-transform: uppercase
                margin-bottom: r(30px)
            + .partner
                margin-left: r(30px)
    .copyright
        font-size: r(12px)
        color: #999999
        letter-spacing: 0.1rem
        margin-top: r(30px)
    @media screen and ( min-width: 1024.98px )
        .section-container
            padding-top: r(160px)
    @media screen and ( max-width: 576px )
        .partner-list
            flex-direction: column
            .partner
                width: 100%
                + .partner
                    margin-left: 0
                    margin-top: r(30px)

.subscribe-form
    line-height: 1.5
    width: 500px
    .wrap-form
        p
            margin-bottom: r(20px)
            font-size: r(18px)
        .form-group
            input[type='text']
                width: 100%
                height: 50px
                padding: 0 15px
                border: 1px solid #666
                font-size: 14px
                &::placeholder
                    font-size: 14px
            + .form-group
                margin-top: r(20px)
        .frm-btn
            margin-top: r(20px)
            input
                width: 120px
                height: 50px
                background: color(main)
                color: #fff
                text-transform: uppercase
                font-family: $font-2
                border: 0
                font-size: 18px
                cursor: pointer