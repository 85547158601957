.diamond-border
    display: inline-flex
    width: r(40px)
    height: r(40px)
    align-items: center
    justify-content: center
    border: 1px solid black
    transform: rotate(45deg)
    transition: 0.3s all
    > *
        transform: rotate(-45deg)
    .material-icons
        font-size: r(24px)

.diamond-border + *
    margin-left: r(20px)

.view-more
    display: inline-flex
    align-items: center
    text-transform: uppercase
    font-family: $font-2
    letter-spacing: 0.1rem
    transition: 0.3s all
    &:before,&:after
        display: block
        content: ''
        width: 20px
        height: 1px
        background: #fff
        transition: 0.3s all
    &:before
        margin-right: 8px
    &:after
        margin-left: 8px
    &:hover
        letter-spacing: 0.25rem
        &:before,&:after
            width: 35px
    @media screen and ( max-width: 1024.98px )
        color: color(main)
        &:before,&:after
            background: color(main)

.btn
    display: inline-flex
    align-items: center
    justify-content: center
    height: r(50px)
    text-transform: uppercase
    font-family: $font-2
    letter-spacing: 0.1rem
    padding: 0 20px
    transition: 0.3s all
    span
        display: flex
        align-items: center
        &:before,&:after
            display: block
            content: ''
            transition: 0.3s all
            width: 20px
            height: 1px
            background: #fff
        &:before
            margin-right: 15px
        &:after
            margin-left: 15px
    &:hover
        letter-spacing: 0.25rem
        &:before,&:after
            span
                width: 35px


.view-more-wrap
    margin-top: r(20px)
    &.has-background
        position: relative
        .view-more
            position: absolute
            top: 50%
            left: 50%
            transform: translate(-50%,-50%)

.swiper-btn
    position: absolute
    top: 50%
    transform: translateY(-50%)
    z-index: 2
    cursor: pointer
    width: 60px
    height: 60px
    &.swiper-btn-prev
        background-image: url(../img/arrow-left.png)
        left: 10%
        &:hover
            background-image: url(../img/arrow-left-active.png)

    &.swiper-btn-next
        background-image: url(../img/arrow-right.png)
        right: 10%
        &:hover
            background-image: url(../img/arrow-right-active.png)