.home-7
    .section-title
        margin-bottom: r(20px)
    @media screen and ( min-width: 1024.98px )
        .section-container
            padding-top: r(160px)

.news-list
    .row
        margin-left: -10px
        margin-bottom: -10px
        > *
            padding-bottom: 10px
            padding-left: 10px
    @media screen and ( max-width: 1024.98px )
        .view-more:after, .view-more:before 
            background: #fff


.news-item
    display: flex
    .news-title
        font-family: $font-2
        font-weight: 700
        line-height: 1.375
        transition: 0.3s all
    .news-img
        overflow: hidden
        img
            transition: 0.3s all
    .news-date
        position: absolute
        top: 0
        right: 0
        width: r(60px)
        height: r(60px)
        display: flex
        flex-direction: column
        align-items: center
        justify-content: center
        color: #fff
        .day
            font-size: r(30px)
        .month-year
            font-size: r(12px)
    .view-detail
        font-family: $font-2
        text-transform: uppercase
        display: flex
        align-items: center
        transition: 0.3s all
        &:after
            content: ''
            display: block
            height: 1px
            width: 20px
            margin-left: r(10px)
        &.text-main
            &:after
                background: color(main)
        &.text-white
            &:after
                background: color(white)
    &.news-first
        position: relative
        &:before
            content: ''
            display: block
            position: absolute
            height: 40%
            left: 0
            bottom: 0
            width: 100%
            background: linear-gradient(0deg, rgba(0,0,0,0.5) 0%, rgba(255,255,255,0) 90%) 
            z-index: 2   
        .news-img
            +img-scale(300/700*100%)
            width: 100%
        .news-title
            position: absolute
            font-size: r(24px)
            color: #fff
            bottom: r(20px)
            text-align: center
            padding: 0 15px
            width: 100%
            z-index: 3
    &.news-row
        > *
            width: 50%
        .news-img
            +img-scale(40%)
        .news-caption
            padding: r(30px)
            display: flex
            flex-direction: column
            justify-content: space-between
            .news-title
                font-size: r(18px)
    &.news-row-reverse
        flex-direction: row-reverse
    &:hover
        .news-title,.view-detail
            text-decoration: underline
        .news-img
            img
                transform: scale(1.15)
    @media screen and ( max-width: 1440.98px )
        &.news-first
            .news-img
                padding-top: 33.33%

        &.news-row
            .news-img
                padding-top: 33.33%
    @media screen and ( max-width: 1024.98px )
        &.news-row-reverse
            flex-direction: row

        

    @media screen and ( max-width: 576px )
        .view-detail
            margin-top: r(20px)
        &.news-first
            display: flex
            flex-direction: column
            .news-img
                padding-top: 66.67%

        &.news-row
            flex-direction: column
            > *
                width: 100%
            .news-img
                padding-top: 50%
