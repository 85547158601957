.tooltip-item-v1
    .tooltip-content
        height: 40px
        background: #fff
        display: flex
        align-items: center
        justify-content: center
        text-transform: uppercase
        position: relative
        &:before,&:after
            content: ''
            display: block
            border-bottom: 8px solid #fff
            border-left: 8px solid transparent
            border-right: 8px solid transparent
            height: 0
            width: 40px
            position: absolute
        &:before
            transform: rotate(-90deg)
            left: -24px
        &:after
            transform: rotate(90deg)
            right: -24px

.tooltip-item-v2
    .tooltip-content
        height: 26px
        background: #fff
        display: flex
        align-items: center
        justify-content: center
        position: relative
        padding: 0 15px
        font-size: 14px

.tooltipster-content
    overflow: initial