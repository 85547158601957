.news-detail
    .section-leading
        padding-bottom: 15px
    .content
        padding: 30px 0 0
        border-top: 1px solid #e1e1e1
        font-size: r(18px)
        line-height: 1.5
        text-align: justify
        h2,h3,h4,h5,h6
            font-size: 1.5rem
            font-weight: 600
            margin-top: 10px
        a
            color: color(blue)
    @media screen and ( max-width: 768.98px )
        padding: 44px 0