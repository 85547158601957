//Reset
@import src/_plugins/sass-libraries/reset.sass

//Gridflex
$gl-gridName: row
$gl-gutter: r(30px)
$gl-gutter-vertical: r(30px)
$gl-mq-width: 'min-width'

@import src/_plugins/gridlex/src/gridlex.scss

// Global
body, html
	font-size: 12px
	line-height: 1
	font-family: 'Questrial', sans-serif
	color: #333
	@media (min-width: 1024px) 
		font-size: 13px
	@media (min-width: 1280px)
		font-size: 14px
	@media (min-width: 1600px)
		font-size: 16px

body, html
	@media screen and ( max-width: 1024.98px )
		overflow: initial

*,*:before,*:after
	box-sizing: border-box
	outline: none

a
	text-decoration: none

.img-map-contain
	> div
		// background-size: cover !important
		background-repeat: no-repeat

.frm-btn-reset, .frm-btnwrap label
	display: none !important

.fa-exclamation-triangle
	line-height: 2
	color: red

div[id*=popup_]
	padding: 0

.fancybox-close-small
	background: color(blue)
	opacity: 1
	svg
		path
			fill: #fff

@media screen and ( min-width: 1024.98px )
	.section.active
		.section-content
			animation: fadeInUp
			animation-duration: 1.25s
	.home-3.active
		.container
			animation: fadeInUp
			animation-duration: 1.25s

