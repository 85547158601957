.breadcrumb-wrapper
	background: #fdfdfd
	.breadcrumb
		display: flex
		align-items: center
		height: 40px
		list-style: none
		padding-left: 0
		margin-bottom: 0
		li
			a
				font-size: 14px
				color: #999
			+ li
				padding-left: 5px
				display: flex
				align-items: center
				&::before
					font-family: remixicon
					content: "\EA6E"
					margin-right: 5px
			&:first-child
				a
					font-size: 0
					&::before
						font-family: remixicon
						content: "\EE1D"
						font-size: 18px
			&:last-child
				a
					color: map-get($colors, blue)
