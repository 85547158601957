.home-banner
    padding: 0 !important
    background: color(main)
    .swiper-slide
        max-width: 100%
        width: 100% !important
    .banner-item
        position: relative
        &:before
            content: ''
            position: absolute
            bottom: 0
            left: 0
            width: 100%
            height: r(500px)
            background: linear-gradient(0deg, rgba(0,0,0,0.6) 0%, rgba(255,255,255,0.00) 50%)
            z-index: 2
    .banner-caption
        position: absolute
        bottom: r(130px)
        text-align: center
        width: 100%
        z-index: 3
        .text
            font-weight: 700
            font-size: r(48px)
            font-family: $font-2
            color: color(main)
            line-height: 1.5
    .mouse-scroll-down
        position: absolute
        bottom: r(60px)
        left: 50%
        transform: translateX(-50%)
        z-index: 2
        cursor: pointer  
        transition: 0.3s all
        &:hover
            bottom: r(50px)
    @media screen and ( min-width: 1024.98px )
        .banner-item
            .banner-cover
                height: 100vh
                img
                    height: 100%
                    width: 100%
                    object-fit: cover
    @media screen and ( max-width: 1024.98px )
        .mouse-scroll-down
            display: none
        .banner-caption
            bottom: 20px
            .text
                font-size: r(30px)
                background-image: none
                -webkit-text-fill-color: initial
                background-clip: initial
                color: #fff
                padding: 0 15px
    @media screen and ( max-width: 576px )
        .banner-caption
            .text
                font-size: r(20px)