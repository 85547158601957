.home-4
    background: #fff
    .section-container
        position: relative

.facility-banner-images
    .swiper-slide
        max-width: 100%
        width: 100% !important
    .banner-item
        position: relative
    .banner-cover
        position: relative
        overflow: hidden
    .parallax-inner
        height: 110%
        width: 110%
        position: absolute
        background-size: cover
        background-repeat: no-repeat
        top: 0
        left: 0
    .title-item
        z-index: 2
        text-align: center
    @media screen and ( min-width: 1024.98px )
        .title-item
            bottom: r(210px)
            position: absolute
            left: 50%
            transform: translateX(-50%)
            .section-title
                color: #fff
        .banner-item
            .banner-cover
                height: 100vh
                img
                    height: 100%
                    width: 100%
                    object-fit: cover
    @media screen and ( min-width: 1440.98px )
        .title-item
            bottom: r(210px)
    @media screen and ( max-width: 1024.98px )
        .banner-cover
            +img-scale(56.25%)
        .title-item
            margin-bottom: r(40px)
        .swiper-navigation
            display: none


.facility-banner-info
    max-width: 450px
    .swiper-container
        width: 450px
    .info-item-wrap
        line-height: 1.375
        width: 140px
        height: 140px
        margin: 0 auto
        position: relative
        z-index: 2
        display: flex
        align-items: center
        justify-content: center
        background: none
        cursor: pointer
        .info-item
            z-index: 2
            position: relative
            font-size: 14px
            padding: 0 15px
            text-align: center
            color: #fff
        .info-bg,.info-bg-active
            position: absolute
            z-index: 1
            width: 100%
            height: 100%
            left: 0
            top: 0
            transform: 0.3s all
        .dot-active
            transition: 0.3s all
            opacity: 0
            position: absolute
            z-index: 2
            bottom: 10px
            left: 50%
            transform: translateX(-50%)
        .info-bg
            background-image: url(../img/home/info-bg.png)
        .info-bg-active
            opacity: 0
            background-image: url(../img/home/info-bg-active.png)
        &:hover
            .info-bg-active
                opacity: 1
            .info-bg
                opacity: 0
            .dot-active
                opacity: 1
    .swiper-slide-thumb-active
        .info-item-wrap
            .info-bg-active
                opacity: 1
            .info-bg
                opacity: 0
            .dot-active
                opacity: 1
    @media screen and ( min-width: 1024.98px )
        position: absolute
        bottom: r(30px)
        left: 50%
        transform: translateX(-50%)
        z-index: 2
    @media screen and ( min-width: 1440.98px )
        bottom: r(40px)
    @media screen and ( max-width: 1024.98px )
        margin: r(30px) auto 0
    @media screen and ( max-width: 576px )
        .swiper-container
            width: 100%
