.section
    @media screen and ( max-width: 1024.98px )
	    padding: r(40px) 0

// .section-small
// 	padding: r(30px) 0

.section-container
    height: 100vh
    position: relative
    @media screen and ( max-width: 1024.98px )
        height: auto
        padding: 0 15px
        .container
            padding: 0
    @media screen and ( max-width: 1600.98px )
        height: auto
        
.section-cover
    @media screen and ( min-width: 1024.98px )
        height: 100%
        img
            width: 100%
            height: 100%
            object-fit: cover

.section-content
    margin-bottom: r(40px)
    @media screen and ( min-width: 1024.98px )
        position: absolute
        width: 100%
        left: 0
        z-index: 2
        color: #fff
        margin-bottom: 0

.section-title
    font-size: r(18px)
    text-transform: uppercase
    letter-spacing: 0.1rem
    line-height: 1.5
    @media screen and ( min-width: 1440.98px )
        &.has-line
            &:before
                content: ''
                width: 1px
                height: r(40px)
                background: rgba(#fff, 0.5)
                margin: 0 auto r(15px)
                display: block

.section-leading
    font-size: r(36px)
    font-weight: 900
    font-family: $font-2
    line-height: 1.5
    text-transform: uppercase

.section-desc
    margin: r(25px) auto 0
    font-size: r(18px)
    @media screen and ( max-width: 1024.98px )
        br
            display: none