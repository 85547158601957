.home-1
    .section-content
        text-align: center
        bottom: r(100px)
    .section-desc
        max-width: r(800px)
    @media screen and ( max-width: 1440.98px )
        .section-content
            bottom: r(40px)

.introduce-popup
    width: 1200px

