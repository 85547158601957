#myMenu
    position: fixed
    z-index: 99
    left: 20px
    bottom: 30px
    color: #fff
    li
        + li
            margin-top: 15px
        a
            .number
                border-color: rgba(#fff , 0.5)
                transition: 0.3s all
                strong
                    font-weight: 400
            .name
                display: inline-block
                transform: translateX(-30px)
                opacity: 0
                transition: 0.3s all
        &.active,&:hover
            a
                .number
                    background-color: color(main)
                    border-color: #fff
                .name
                    opacity: 1
                    transform: none
    @media screen and ( max-width: 1024.98px )
        display: none
