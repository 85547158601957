.home-5
    background-color: color(blue)
    .swiper-container
        width: 100vw
        max-width: 100%
    .swiper-slide
        max-width: 100%

    .shophouse-item
        position: relative
        display: block
        &:before
            content: ''
            display: block
            position: absolute
            height: 60%
            left: 0
            bottom: 0
            width: 100%
            background: linear-gradient(0deg, rgba(14,32,68,1) 0%, rgba(255,255,255,0) 90%) 
            z-index: 2
            pointer-events: none    
        .img
            +img-scale(720 / 1410 * 100%)
            transition: 0.3s all
            background: color(blue)
            img
                opacity: 0.65
        .content
            position: absolute
            bottom: 20px
            text-align: center
            left: 0
            right: 0
            padding: 0 15px
            z-index: 3
            opacity: 0
            transition: 0.3s all
            .section-leading
                line-height: 1.4
            .section-desc
                color: #fff
                margin-top: r(15px)
                line-height: 1.25

    .swiper-slide-active
        .shophouse-item
            .img
                img
                    opacity: 1
            .content
                opacity: 1

    .view-more
        color: #fff

    @media screen and ( min-width: 1600.98px )
        .section-container
            padding-top: r(152px)
    @media screen and ( max-width: 768.98px )
        .swiper-navigation
            display: none
        .shophouse-item
            &:before
                display: none
            .content
                position: static
                margin-top: 15px
                .section-leading
                    font-size: r(20px)
                .section-desc
                    font-size: 1rem
                    margin-top: r(10px)