header
    padding: r(20px) 0
    position: fixed
    z-index: 999
    top: 0
    left: 0
    width: 100%
    color: #fff
    background: linear-gradient(180deg, rgba(0,0,0,0.2) 0%, rgba(255,255,255,0.00) 80%)
    transition: 0.3s all
    .diamond-border
        border-color: #fff
    &.active
        color: color(main)
        background: transparent !important
        .diamond-border 
            border-color: color(main)
    &.scrolling
        background: color(blue)

.header-wrap
    display: flex
    justify-content: space-between
    align-items: center
    position: relative
    min-height: r(112px)
    @media screen and ( max-width: 1024.98px )
        min-height: 60px

.header-item-contain
    display: flex
    align-items: center
    > *
        + *
            margin-left: r(84px)
    @media screen and ( max-width: 1024.98px )
        > *
            + *
                margin-left: r(40px)
    @media screen and ( max-width: 576px )
        > *
            + *
                margin-left: r(32px)

.header-brand
    .logo
        position: absolute
        top: 50%
        left: 50%
        transform: translate(-50%,-50%)
        img
            max-height: r(112px)
            max-width: r(144px)
        &.logo-color
            display: none
    @media screen and ( max-width: 1024.98px )
        .logo
            img
                max-height: 60px

.menu-toggle,.hotline
    display: flex
    align-items: center
    cursor: pointer
    .text
        font-size: 14px
        letter-spacing: 0.1rem
        text-transform: uppercase
    @media screen and ( max-width: 1024.98px )
        .text
            display: none

.header-modal
    position: fixed
    z-index: 998
    top: 0
    left: 0
    width: 100%
    height: 100%
    background-image: url(../img/menu-bg.png)
    background-repeat: no-repeat
    background-size: cover
    padding-top: r(180px)
    transform: translateY(-100%)
    transition: 1s all
    &.active
        transform: none
        transition: 0.5s all
    @media screen and ( max-width: 1024.98px )
        padding-top: r(140px)

.header-menu
    .menu
        text-align: center
        
        &:before
            content: ''
            width: 1px
            height: r(40px)
            background: rgba(color(main), 0.5)
            margin: 0 auto r(40px)
            display: block
        ul
            li
                a
                    font-family: $font-2
                    font-size: r(30px)
                    text-transform: uppercase
                    font-weight: 900
                    color: color(main)
                    transition: 0.3s all
                    &:hover
                        color: #fff
                &.active
                    a
                        color: #fff
                + li
                    margin-top: r(30px)
    @media screen and ( max-width: 1368.98px )
        padding-top: r(160px)
        .menu
            &:before
                margin-bottom: r(20px)
            ul
                li
                    a
                        font-size: r(24px)
                    + li
                        margin-top: r(20px)

.header-social
    margin-top: r(60px)
    ul
        display: flex
        justify-content: center
        li
            + li
                margin-left: r(30px)
            a
                border-color: color(main)
                color: color(main)
                transition: 0.3s all
                &:hover
                    color: #fff
                    border-color: #fff
    @media screen and ( max-width: 1368.98px )
        margin-top: r(40px)

.header-search
    text-align: center
    .module-title
        font-family: $font-2
        font-size: r(40px)
        text-transform: uppercase
        font-weight: 900
        color: color(main)
        margin-bottom: r(40px)
        &:before
            content: ''
            width: 1px
            height: r(40px)
            background: rgba(color(main), 0.5)
            margin: 0 auto r(40px)
            display: block
    .searchbox
        display: inline-block
        width: 50%
        position: relative
        padding: 0 r(20px)
        input
            border: 0
            border-bottom: 1px solid color(main)
            background: transparent
            height: r(59px)
            width: 100%
            font-size: r(24px)
            color: color(main)
            font-family: $font-2
            transition: 0.3s all
            padding-right: r(60px)
            &:focus,&:active
                color: #fff
                border-color: #fff
        button
            background: transparent
            color: color(main)
            border: 0
            height: r(60px)
            position: absolute
            right: 0
            span
                font-size: r(40px)
    @media screen and ( max-width: 768.98px )
       .searchbox
           width: 90%
        
.menu-toggle,.language,.hotline,.search-toggle
    @media screen and ( min-width: 1024.98px )
        &:hover
            .diamond-border
                background: rgba(color(main), 0.5)
                color: #fff